//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XLSX from 'xlsx';
import {
  createDateCurrentMinusOneMinute,
  createDateCurrentMinusFourDays,
  createDateCurrentPlusThreeDays,
  createTwoDigitNumberFromNumber,
  dateTimeHelpers,
  getJustDateStringFromDate,
  createDateCurrentWithoutTimezoneOffset,
  getJustTimeStringFromDate,
  getHoursAndMinutesFromString, regexFormatDate, createISODateString, localeDateOptions, ruLocaleDateString,
} from "../utils/dateTimeHelpers";


export default {
  async fetch() {
    const [{content: databases}, {content: profiles}] = await Promise.all([
      this.$api.fetch.databases(),
      this.$api.fetch.profiles()
    ]);

    this.databases = databases;
    this.profiles = profiles;
    this.profile = 2;
  },

  data: () => ({
    internal_db: void 0,
    database: void 0,
    databases: [],

    profile: void 0,
    profiles: [],
    operators: [],
    selected_operators: [],

    files: [],

    errors: void 0,

    menu: false,
    date: (getJustDateStringFromDate(createDateCurrentWithoutTimezoneOffset())),
    dates: [],

    uploading: false,
    upfiles: {},
    loaded: false,
    file_no: 0,
    files_count: 0,

    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    startDateTime: '',
    endDateTime: '',
    menu1: false,
    menu2: false,
    minStartDate: createDateCurrentMinusFourDays().toISOString(),
    maxStartDate: createDateCurrentMinusOneMinute().toISOString()
  }),

  computed: {
    isReparseDisabled() {
      if (this.startDateTime && this.endDateTime) {
        return typeof this.startDateRule(this.startDateTime) === 'string' && typeof this.endDateRule(this.endDateTime) === 'string';
      }

      return true;
    },

    minEndTime() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      if (this.startDate && this.endDate && getJustDateStringFromDate(startDate) === getJustDateStringFromDate(endDate)) {
        const hours = createTwoDigitNumberFromNumber(this.startTime.getHours());
        const minutes = createTwoDigitNumberFromNumber(this.startTime.getMinutes());

        return `${hours}:${minutes}`;
      }

      return null;
    },

    minStartTime() {
      if (!this.startDate || !this.minStartDate) {
        return null;
      }

      const selectedDate = getJustDateStringFromDate(new Date(this.startDate));
      const minDate = getJustDateStringFromDate(new Date(this.minStartDate));

      if (selectedDate !== minDate) {
        return null;
      }

      const fiveMinutes = 5;
      const minDateTime = new Date(this.minStartDate);
      minDateTime.setMinutes(minDateTime.getMinutes() + fiveMinutes);
      const minStartTime = getJustTimeStringFromDate(minDateTime);

      return minStartTime;
    },

    maxStartTime() {
      if (this.startDate) {
        return null;
      }

      const currentDate = new Date();
      const startDate = new Date(this.startDate);

      if (getJustDateStringFromDate(startDate) !== getJustDateStringFromDate(currentDate)) {
        return null;
      }
      const hours = createTwoDigitNumberFromNumber(currentDate.getHours());
      const minutes = createTwoDigitNumberFromNumber(currentDate.getMinutes() - 1);

      return `${hours}:${minutes}`;
    },

    maxEndDate() {
      return createDateCurrentPlusThreeDays().toISOString();
    },

    maxEndTime() {
      if (this.startDate) {
        const currentDate = new Date();
        const endDate = new Date(this.endDate);

        if (getJustDateStringFromDate(endDate) !== getJustDateStringFromDate(currentDate)) {
          return null;
        }

        const hours = createTwoDigitNumberFromNumber(currentDate.getHours());
        const minutes = createTwoDigitNumberFromNumber(currentDate.getMinutes() - 1);

        return `${hours}:${minutes}`;
      }

      return null;
    },

    database_items() {
      const reduced = this.databases.reduce((memo, item, inx) => {
        memo[item.meta.name] = {
          text: item.meta.name,
          value: `${item.database}:${inx}`
        };

        return memo;
      }, {});

      return Object.values(reduced);
    }
  },

  methods: {
    updateStartDate(date) {
      this.startDate = date;
      this.combineDateTime('start');
    },

    updateStartTime(time) {
      const [hours, minutes] = getHoursAndMinutesFromString(time);
      this.startTime = new Date();
      this.startTime.setHours(hours, minutes, 0, 0);
      this.combineDateTime('start');
    },

    updateEndDate(date) {
      this.endDate = date;
      this.combineDateTime('end');
    },

    updateEndTime(time) {
      const [hours, minutes] = getHoursAndMinutesFromString(time);
      this.endTime = new Date();
      this.endTime.setHours(hours, minutes, 0, 0);
      this.combineDateTime('end');
    },

    combineDateTime(type) {
      let hours, minutes;
      if (type === 'start' && this.startDate && this.startTime) {
        const date = new Date(this.startDate);
        date.setHours(this.startTime.getHours());
        date.setMinutes(this.startTime.getMinutes());
        this.startDateTime = date.toLocaleString(ruLocaleDateString, localeDateOptions);
      } else if (type === 'end' && this.endDate && this.endTime) {
        const date = new Date(this.endDate);

        if (typeof this.endTime !== "object") {
          [hours, minutes] = this.endTime.split(':').map(Number);

        } else {
          hours = this.endTime.getHours();
          minutes = this.endTime.getMinutes();
        }
        date.setHours(hours);
        date.setMinutes(minutes);

        this.endDateTime = date.toLocaleString(ruLocaleDateString, localeDateOptions);
      }
    },

    async report() {
      this.menu = false;

      const {database, dates} = this;

      const {content} = await this.$api.fetch.report({database, dates, operators: this.selected_operators});

      const wb = XLSX.utils.book_new();

      const headers = [
        'Название файла',
        'Кадастровый номер',
        'Название проекта',
        'Дата актуальности',
        'Последняя дата регистрации',
        'Кол-во строк',
        'Пользователь'
      ];

      const ws_data = content.map(data => {
        const filename = decodeURIComponent(data.name);

        return [
          filename,
          data.meta.info.cadastral_number,
          data.meta.info.projects,
          data.meta.info.document_date,
          data.meta.info.last_date,
          +data.meta.data,
          [data.firstname, data.lastname].filter(item => !!item).join(' ')
        ];
      });

      const ws = XLSX.utils.aoa_to_sheet([
        headers,
        ...ws_data
      ]);

      XLSX.utils.book_append_sheet(wb, ws, 'Статистика');
      XLSX.writeFile(wb, `statement-${dates.sort().join('-')}.xlsx`);
    },

    required(value) {
      return !!value || 'Поле обязательно для заполнения.';
    },

    filled(value) {
      return value.length ? true : 'Поле обязательно для заполнения.';
    },

    async upload() {
      this.errors = void 0;

      if (!this.$refs.form.validate()) {
        return;
      }

      this.uploading = true;
      this.upfiles = {};
      this.loaded = false;

      let {database, profile} = this;

      this.files_count = this.files.length;
      this.file_no = 0;

      for (const file of this.files) {
        this.file_no = this.file_no + 1;

        const params = {
          encoding: 'win1251',
          name: encodeURIComponent(file.name),
          size: file.size
        };

        this.$set(this.upfiles, file.name, {...params, status: 'загрузка...', error: ''});

        const data = new FormData();
        data.append('file', file, JSON.stringify(params));

        data.append('count', 1);
        database && data.append('database', database);
        profile && data.append('profile', profile);

        if (this.files.length) {
          const {content, error} = await this.$api.upload.files(data, {silent: true}).catch(error => {
            return {error};
          });

          let errors = error ? [error] : content.errors.length ? content.errors : [];

          if (errors.length) {
            let [error] = errors;

            this.upfiles[file.name].error = error.message;
          } else {
            this.upfiles[file.name].status = '100% OK';
          }
        }
      }

      this.$store.dispatch('setDatabase', database);

      this.profile = void 0;
      this.files = [];

      this.$refs.form && this.$refs.form.resetValidation();

      this.$nextTick(() => this.database = database);

      this.loaded = true;
    },

    async signout() {
      await this.$api.auth.signout();
      this.$store.commit('SET_ACCOUNT', {});
    },

    startDateRule(date) {
      if (regexFormatDate.test(date)) {
        const formattedDate = createISODateString(date);

        return formattedDate >= this.minStartDate || 'Дата и время не могут быть меньше, чем четыре дня назад';
      }

      return true;
    },

    endDateRule(date) {
      if (regexFormatDate.test(date)) {
        const formattedDate = createISODateString(date);

        return formattedDate >= this.minStartDate || 'Дата и время не могут быть меньше, чем в поле даты отсчета';
      }

      return true;
    },

    async submitMassReparse() {
      const startDateFormatted = dateTimeHelpers(this.startDateTime);
      const endDateFormatted = dateTimeHelpers(this.endDateTime);

      const dataToReparse = {
        "from": startDateFormatted,
        "to": endDateFormatted,
        "profile": this.profile,
        "database": this.databases[0].database
      };
      const responseReparse = await this.$api.upload.reparse.period(dataToReparse, {silent: true});
      this.resetDateInputs();

      return responseReparse;
    },

    resetDateInputs() {
      this.startDate = null;
      this.startTime = null;
      this.endDate = null;
      this.endTime = null;
      this.startDateTime = '';
      this.endDateTime = '';
    }
  },

  watch: {
    startDateTime(newValue) {
      if (!newValue || !this.startDateTime) {
        return null;
      }

      const [datePart, timePart] = newValue.split(', ');
      const [day, month, year] = datePart.split('.').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);

      const startDate = new Date(year, month - 1, day, hours, minutes, seconds || 0);
      const endDate = new Date(startDate.getTime());

      this.endDateTime = endDate.toLocaleString(ruLocaleDateString, localeDateOptions);
      this.endDate = endDate.toISOString().substr(0, 10);
      this.endTime = endDate.toTimeString().substr(0, 5);
    },

    internal_db(value) {
      const [database] = value.split(':');
      this.database = database;
    },

    async database(database) {
      const {content: operators} = await this.$api.fetch.operators({database});

      if (operators?.length) {
        this.operators = operators.map(operator => {
          const name = [operator.firstname, operator.lastname].filter(x => !!x).join(' ');

          operator = {
            id: operator.id,
            name
          };

          return operator;
        });
      } else {
        this.operators = [];
      }

      this.$store.dispatch('setDatabase', database);
    }
  }
}
